<template>
  <section :class="{ 'm-2': isPdv }">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col
            v-if="isErp"
            md="3"
          >
            <e-store-combo
              id="filters-store"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>

          <b-col md="2">
            <e-bar-tab-number-input
              id="pay_box_bar_tab-number"
              v-model="filters.number"
              :label="$t('Número')"
            />
          </b-col>

          <b-col :md="isErp ? 3 : 4">
            <FormulateInput
              id="pay_box_bar_tab-consumerName"
              v-model="filters.consumerName"
              :label="$t('Nome do cliente')"
            />
          </b-col>

          <b-col :md="isErp ? 4 : 6">
            <e-search-sku
              id="pay_box_bar_tab-sku_id"
              v-model="filters.skuId"
              :required="false"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="pay_box_bar_tab-status"
              v-model="filters.status"
              :label="$t('Status Comanda')"
              :placeholder="$t('Selecione')"
              type="vue-select"
              :options="barTabStatusOptions()"
            />
          </b-col>

          <b-col md="3">
            <FormulateInput
              id="pay_box_bar_tab-consumption_status"
              v-model="filters.consumptionStatus"
              :label="$t('Status consumo')"
              :placeholder="$t('Selecione')"
              type="vue-select"
              :options="barTabConsumptionStatusWithoutCloseOptions()"
            />
          </b-col>
        </b-row>
      </div>

      <template #after_buttons>
        <e-button
          v-if="isPdv"
          id="bar_tab_list-open_bar_tab"
          class="ml-1"
          variant="primary"
          icon="plusSquare"
          :text="$t('Abrir comanda')"
          @click="onShowBarTabOpenModal"
        />
        <e-button
          v-if="isPdv"
          id="bar_tab_list-close_bar_tab"
          class="ml-1"
          variant="primary"
          icon="xSquare"
          :text="$t('Fechar comanda')"
          @click="onShowBarTabCloseModal"
        />
      </template>
    </e-filters>

    <b-card-actions
      class="mb-2"
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('BAR_TAB.NAME'), 'female')"
        :fields="fields"
        :items="barTabs"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(status)="row">
          <e-status-badge
            :status="row.item.status"
            :options="barTabStatusOptions()"
          />
        </template>
        <template #cell(barTabConsumptionStatus)="row">
          <e-status-badge
            v-if="row.item.barTabConsumption"
            :status="row.item.barTabConsumption.status"
            :options="barTabConsumptionStatusOptions()"
          />
          <span v-else> - </span>
        </template>
        <template #cell(barTabConsumptionPaymentConfirmed)="row">
          <e-status-badge
            v-if="row.item.barTabConsumption"
            :status="row.item.barTabConsumption.paymentConfirmed"
            type="yesNo"
          />
          <span v-else> - </span>
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-delete="false"
            :show-update="false"
            show-read-only
            @read-only="onShowBarTabConsumption(row.item)"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="table-manage-pay-box"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <bar-tab-consumption-info-sidebar
      ref="barTabConsumptionInfoSidebar"
      :read-only="isErp"
      @reload-data="getData"
      @show-modal-comission="onShowComissionModal"
    />

    <modal-bar-tab-open
      ref="modalBarOpen"
      @after-open="getData"
    />

    <modal-bar-tab-close
      ref="modalBarClose"
      @after-finish="getData"
    />

    <modal-comission
      ref="modalComissionTabManagement"
      @click-confirm="onConfirmComissionModal"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import { barDomain, translate } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import isElectron from 'is-electron'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import EButton from '@/views/components/EButton.vue'
import EBarTabNumberInput from '@/views/components/inputs/EBarTabNumberInput.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import BarTabConsumptionInfoSidebar from './BarTabConsumptionInfoSidebar.vue'
import ModalBarTabOpen from './components/modals/ModalBarTabOpen.vue'
import ModalBarTabClose from './components/modals/ModalBarTabClose.vue'
import ModalComission from '../pay-box/components/modals/ModalComission.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EStatusBadge,
    EFilters,
    EGridActions,
    ESearchSku,
    BarTabConsumptionInfoSidebar,
    ModalBarTabOpen,
    EButton,
    ModalBarTabClose,
    ModalComission,
    EBarTabNumberInput,
    EStoreCombo,
  },

  mixins: [translate, barDomain],

  props: {
    isErp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/pdv/payBoxBar/tabs', {
      stPdvBarTabs: 'barTabs',
      stPdvPaging: 'paging',
      stPdvSorting: 'sorting',
      stPdvFilters: 'filters',
    }),
    ...mapState('pages/bar', {
      stErpBarTabs: 'barTabs',
      stErpPaging: 'paging',
      stErpSorting: 'sorting',
      stErpFilters: 'filters',
    }),

    barTabs() {
      return this.isErp ? this.stErpBarTabs : this.stPdvBarTabs
    },
    paging() {
      return this.isErp ? this.stErpPaging : this.stPdvPaging
    },
    sorting() {
      return this.isErp ? this.stErpSorting : this.stPdvSorting
    },
    filters() {
      return this.isErp ? this.stErpFilters : this.stPdvFilters
    },

    canDownloadDesktopApp() {
      return this.$can('Setup', 'PayBox') && !isElectron()
    },

    isPdv() {
      return !this.isErp
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Loja'),
          key: 'store.id',
          tdClass: 'text-left',
          thStyle: { width: '280px' },
          formatter: (val, index, item) => this.$options.filters.storeName(item.store),
          sortable: true,
          hide: this.isPdv,
        },
        {
          label: this.$t('Número'),
          key: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Status comanda'),
          key: 'status',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Status Consumo'),
          key: 'barTabConsumptionStatus',
          sortKey: 'barTabConsumption.status',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          // sortable: true,
        },
        {
          label: this.$t('Pagamento confirmado'),
          key: 'barTabConsumptionPaymentConfirmed',
          sortKey: 'barTabConsumption.paymentConfirmed',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          // sortable: true,
        },
        {
          label: this.$t('Data/Hora Abertura'),
          key: 'openingDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: (val, index, item) =>
            item.barTabConsumption
              ? this.$options.filters.datetime(item.barTabConsumption.createdDate, '-')
              : '-',
        },
        {
          label: this.$t('Cliente'),
          key: 'consumerName',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, index, item) => item.barTabConsumption?.consumerName || '-',
        },
        {
          label: this.$t('Total'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '130px' },
          formatter: (val, index, item) =>
            this.$options.filters.currency(item?.barTabConsumption?.netValue || 0),
        },
        // {
        //   label: this.$t('Loja'),
        //   key: 'store.id',
        //   thClass: 'text-center',
        //   tdClass: 'text-left',
        //   formatter: (val, index, item) => this.$options.filters.storeName(item.store),
        //   sortable: true,
        // },
      ].filter(f => !f.hide)
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/pdv/payBoxBar/tabs', {
      stPdvfetchBarTabs: 'fetchBarTabs',
      stPdvSetCurrentPage: 'setCurrentPage',
      stPdvSetPageSize: 'setPageSize',
      stPdvResetFilters: 'resetFilters',
    }),
    ...mapActions('pages/bar', {
      stErpfetchBarTabs: 'fetchBarTabs',
      stErpSetCurrentPage: 'setCurrentPage',
      stErpSetPageSize: 'setPageSize',
      stErpResetFilters: 'resetFilters',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true

          if (this.isErp) {
            await this.stErpfetchBarTabs()
          } else {
            await this.stPdvfetchBarTabs()
          }
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      if (this.isErp) this.stErpResetFilters()
      else this.stPdvResetFilters()
      this.getData()
    },
    filterData() {
      if (this.isErp) this.stErpSetCurrentPage(1)
      else this.stPdvSetCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      if (this.isErp) this.stErpSetPageSize(pageSize)
      else this.stPdvSetPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      if (this.isErp) this.stErpSetCurrentPage(currentPage)
      else this.stPdvSetCurrentPage(currentPage)
      this.getData()
    },

    onShowBarTabConsumption(item) {
      if (item.status === this.barTabStatusEnum.CLOSED) {
        this.showWarning({
          message: this.$t('Não é possível visualizar os consumos de uma comanda fechada'),
        })
        return
      }

      this.$refs.barTabConsumptionInfoSidebar.show(item.barTabConsumption.id)
    },

    onShowBarTabOpenModal() {
      this.$refs.modalBarOpen.showModal()
    },
    onShowBarTabCloseModal() {
      this.$refs.modalBarClose.showModal()
    },

    onShowComissionModal(data) {
      this.$refs.modalComissionTabManagement.showModal({ comissionFee: data.comissionFee })
    },
    onConfirmComissionModal(formData) {
      this.$refs.barTabConsumptionInfoSidebar.onConfirmComission(formData)
    },
  },
}
</script>

<style lang="scss" scoped></style>

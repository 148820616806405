<template>
  <FormulateInput
    v-if="isReadOnly"
    v-model="content"
    v-bind="$attrs"
    type="label"
    :label="label"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
  />
  <FormulateInput
    v-else
    v-model.number="content"
    v-bind="$attrs"
    :label="label"
    type="text"
    dir="rtl"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    @input="handleInput"
    @blur="handleBlur"
    @focus="handleFocus"
  />
</template>

<script>
import _ from 'lodash'

export default {
  components: {},

  mixins: [],

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      content: null,
    }
  },

  computed: {},

  watch: {
    value(val) {
      this.content = val
    },
  },

  created() {
    this.content = this.value
  },

  methods: {
    handleInput() {
      this.content = this.formatValue(this.content)
      this.$emit('input', this.content)
    },
    handleBlur() {
      this.content = this.formatValue(this.content)
      this.$emit('blur', this.content)
    },
    handleFocus(event) {
      const target = document.querySelector(`input#${event.target.id}`)
      if (target) target.select()
      this.$emit('focus', target?.value, event)
    },

    formatValue(val) {
      if (_.isNil(val) || val === '') return val
      return String(val).padStart(10, '0')
    },
  },
}
</script>

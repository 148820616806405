var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-bar-tab-open",
        title: _vm.$t("Abrir comanda"),
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        centered: "",
        size: "md",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "formBarTabOpen",
          attrs: { name: "formBarTabOpen" },
          on: { submit: _vm.onConfirm },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "5" } },
                    [
                      _c("e-bar-tab-number-input", {
                        attrs: {
                          id: "modal_bar_tab_open-number",
                          name: "number",
                          label: _vm.$t("Número da comanda"),
                          required: "",
                        },
                        model: {
                          value: _vm.form.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "number", $$v)
                          },
                          expression: "form.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          label: _vm.$t("Pesquisar cliente Echope"),
                          "is-customer": "",
                        },
                        on: { input: _vm.onSelectCustomer },
                        model: {
                          value: _vm.form.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "customerId", $$v)
                          },
                          expression: "form.customerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "modal_bar_tab_open-consumer_name",
                          name: "consumerName",
                          type: "text",
                          label: _vm.$t("Nome"),
                          validation: "required",
                          disabled: _vm.fetchingCustomer,
                        },
                        model: {
                          value: _vm.form.consumerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "consumerName", $$v)
                          },
                          expression: "form.consumerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(##) #####-####",
                            expression: "'(##) #####-####'",
                          },
                        ],
                        attrs: {
                          id: "modal_bar_tab_open-consumer_phone",
                          name: "consumerPhone",
                          type: "text",
                          label: _vm.$t("Telefone"),
                          disabled: _vm.fetchingCustomer,
                        },
                        model: {
                          value: _vm.form.consumerPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "consumerPhone", $$v)
                          },
                          expression: "form.consumerPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "modal_bar_tab_open-observation",
                          name: "observation",
                          type: "textarea",
                          label: _vm.$t("Observação"),
                        },
                        model: {
                          value: _vm.form.observation,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "observation", $$v)
                          },
                          expression: "form.observation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_bar_tab_open-btn_cancel",
                          variant: "danger",
                          text: _vm.$t("Cancelar"),
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal_bar_tab_open-btn_confirm",
                          variant: "primary",
                          type: "submit",
                          text: _vm.$t("Confirmar"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: { "m-2": _vm.isPdv } },
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _vm.isPdv
                    ? _c("e-button", {
                        staticClass: "ml-1",
                        attrs: {
                          id: "bar_tab_list-open_bar_tab",
                          variant: "primary",
                          icon: "plusSquare",
                          text: _vm.$t("Abrir comanda"),
                        },
                        on: { click: _vm.onShowBarTabOpenModal },
                      })
                    : _vm._e(),
                  _vm.isPdv
                    ? _c("e-button", {
                        staticClass: "ml-1",
                        attrs: {
                          id: "bar_tab_list-close_bar_tab",
                          variant: "primary",
                          icon: "xSquare",
                          text: _vm.$t("Fechar comanda"),
                        },
                        on: { click: _vm.onShowBarTabCloseModal },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _vm.isErp
                    ? _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c("e-store-combo", {
                            attrs: { id: "filters-store", required: false },
                            model: {
                              value: _vm.filters.storeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "storeId", $$v)
                              },
                              expression: "filters.storeId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("e-bar-tab-number-input", {
                        attrs: {
                          id: "pay_box_bar_tab-number",
                          label: _vm.$t("Número"),
                        },
                        model: {
                          value: _vm.filters.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "number", $$v)
                          },
                          expression: "filters.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: _vm.isErp ? 3 : 4 } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "pay_box_bar_tab-consumerName",
                          label: _vm.$t("Nome do cliente"),
                        },
                        model: {
                          value: _vm.filters.consumerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "consumerName", $$v)
                          },
                          expression: "filters.consumerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: _vm.isErp ? 4 : 6 } },
                    [
                      _c("e-search-sku", {
                        attrs: {
                          id: "pay_box_bar_tab-sku_id",
                          required: false,
                        },
                        model: {
                          value: _vm.filters.skuId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "skuId", $$v)
                          },
                          expression: "filters.skuId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "pay_box_bar_tab-status",
                          label: _vm.$t("Status Comanda"),
                          placeholder: _vm.$t("Selecione"),
                          type: "vue-select",
                          options: _vm.barTabStatusOptions(),
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "pay_box_bar_tab-consumption_status",
                          label: _vm.$t("Status consumo"),
                          placeholder: _vm.$t("Selecione"),
                          type: "vue-select",
                          options:
                            _vm.barTabConsumptionStatusWithoutCloseOptions(),
                        },
                        model: {
                          value: _vm.filters.consumptionStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "consumptionStatus", $$v)
                          },
                          expression: "filters.consumptionStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          staticClass: "mb-2",
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("BAR_TAB.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.barTabs,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(status)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: {
                        status: row.item.status,
                        options: _vm.barTabStatusOptions(),
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(barTabConsumptionStatus)",
                fn: function (row) {
                  return [
                    row.item.barTabConsumption
                      ? _c("e-status-badge", {
                          attrs: {
                            status: row.item.barTabConsumption.status,
                            options: _vm.barTabConsumptionStatusOptions(),
                          },
                        })
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "cell(barTabConsumptionPaymentConfirmed)",
                fn: function (row) {
                  return [
                    row.item.barTabConsumption
                      ? _c("e-status-badge", {
                          attrs: {
                            status: row.item.barTabConsumption.paymentConfirmed,
                            type: "yesNo",
                          },
                        })
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-delete": false,
                        "show-update": false,
                        "show-read-only": "",
                      },
                      on: {
                        "read-only": function ($event) {
                          return _vm.onShowBarTabConsumption(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "table-manage-pay-box",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("bar-tab-consumption-info-sidebar", {
        ref: "barTabConsumptionInfoSidebar",
        attrs: { "read-only": _vm.isErp },
        on: {
          "reload-data": _vm.getData,
          "show-modal-comission": _vm.onShowComissionModal,
        },
      }),
      _c("modal-bar-tab-open", {
        ref: "modalBarOpen",
        on: { "after-open": _vm.getData },
      }),
      _c("modal-bar-tab-close", {
        ref: "modalBarClose",
        on: { "after-finish": _vm.getData },
      }),
      _c("modal-comission", {
        ref: "modalComissionTabManagement",
        on: { "click-confirm": _vm.onConfirmComissionModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <b-modal
      id="modal-bar-tab-close"
      :title="$t('Fechar comanda')"
      no-close-on-backdrop
      hide-header-close
      hide-footer
      centered
      size="md"
      @hidden="resetModal"
    >
      <FormulateForm
        ref="formBarTabClose"
        name="formBarTabClose"
        @submit="onConfirm"
      >
        <b-container>
          <b-row>
            <b-col md="5">
              <e-bar-tab-number-input
                id="modal_bar_tab_close-number"
                v-model="form.number"
                name="number"
                :label="$t('Número da comanda')"
                required
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col class="d-flex justify-content-end">
              <e-button
                id="modal_bar_tab_close-btn_cancel"
                class="mr-1"
                variant="danger"
                :text="$t('Cancelar')"
                :text-shortcuts="['ESC']"
                @click="hideModal"
              />
              <e-button
                id="modal_bar_tab_close-btn_confirm"
                variant="primary"
                type="submit"
                :text="$t('Confirmar')"
              />
            </b-col>
          </b-row>
        </b-container>
      </FormulateForm>
    </b-modal>

    <bar-tab-consumption-info-sidebar
      ref="barTabConsumptionInfoSidebarInModal"
      @reload-data="$emit('after-finish')"
    />
  </div>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import { formulateHelper } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import { mapGetters } from 'vuex'
import EBarTabNumberInput from '@/views/components/inputs/EBarTabNumberInput.vue'
import BarTabConsumptionInfoSidebar from '../../BarTabConsumptionInfoSidebar.vue'

const getInitialForm = () => ({
  number: '',
})

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    EButton,
    BarTabConsumptionInfoSidebar,
    EBarTabNumberInput,
  },

  mixins: [formulateHelper],

  props: {},

  data() {
    return {
      form: getInitialForm(),
      saving: false,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration']),
  },

  methods: {
    async showModal() {
      try {
        this.$bvModal.show('modal-bar-tab-close')

        this.$nextTick(() => {
          this.focusInput('input#modal_bar_tab_close-number')
        })
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },

    hideModal() {
      this.$bvModal.hide('modal-bar-tab-close')
    },

    resetModal() {
      this.form = getInitialForm()
    },

    async onConfirm() {
      if (this.$refs.formBarTabClose.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      this.saving = true
      const { store } = this.currentPayboxConfiguration
      const { number } = this.form

      try {
        const { data } = await this.$http.get(
          `/api/bar-tab/consumption/store/${store?.id}/tab-number/${number}`
        )
        if (data.barTabConsumption?.id === 0) {
          this.showWarning({ message: `Comanda ${number} já está fechada` })
          return
        }

        this.$refs.barTabConsumptionInfoSidebarInModal.show(data.barTabConsumption.id)
        this.hideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>

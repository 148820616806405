<template>
  <b-modal
    id="modal-bar-tab-open"
    :title="$t('Abrir comanda')"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    centered
    size="md"
    @hidden="resetModal"
  >
    <FormulateForm
      ref="formBarTabOpen"
      name="formBarTabOpen"
      @submit="onConfirm"
    >
      <b-container>
        <b-row>
          <b-col md="5">
            <e-bar-tab-number-input
              id="modal_bar_tab_open-number"
              v-model="form.number"
              name="number"
              :label="$t('Número da comanda')"
              required
            />
          </b-col>

          <b-col md="12">
            <e-person-search
              v-model="form.customerId"
              :label="$t('Pesquisar cliente Echope')"
              is-customer
              @input="onSelectCustomer"
            />
          </b-col>

          <b-col md="6">
            <FormulateInput
              id="modal_bar_tab_open-consumer_name"
              v-model="form.consumerName"
              name="consumerName"
              type="text"
              :label="$t('Nome')"
              validation="required"
              class="required"
              :disabled="fetchingCustomer"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="modal_bar_tab_open-consumer_phone"
              v-model="form.consumerPhone"
              v-mask="'(##) #####-####'"
              name="consumerPhone"
              type="text"
              :label="$t('Telefone')"
              :disabled="fetchingCustomer"
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              id="modal_bar_tab_open-observation"
              v-model="form.observation"
              name="observation"
              type="textarea"
              :label="$t('Observação')"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col class="d-flex justify-content-end">
            <e-button
              id="modal_bar_tab_open-btn_cancel"
              class="mr-1"
              variant="danger"
              :text="$t('Cancelar')"
              :text-shortcuts="['ESC']"
              @click="hideModal"
            />
            <e-button
              id="modal_bar_tab_open-btn_confirm"
              variant="primary"
              type="submit"
              :text="$t('Confirmar')"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import { formulateHelper, telephoneTypes } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import { mapGetters } from 'vuex'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import EBarTabNumberInput from '@/views/components/inputs/EBarTabNumberInput.vue'

const getInitialForm = () => ({
  number: '',
  consumerName: '',
  consumerPhone: '',
  customerId: null,
  observation: '',
})

export default {
  components: { BModal, BContainer, BRow, BCol, EButton, EPersonSearch, EBarTabNumberInput },

  mixins: [formulateHelper, telephoneTypes],

  props: {},

  data() {
    return {
      form: getInitialForm(),
      saving: false,
      fetchingCustomer: false,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration']),
  },

  methods: {
    async showModal() {
      try {
        this.$bvModal.show('modal-bar-tab-open')

        this.$nextTick(() => {
          this.focusInput('input#modal_bar_tab_open-number')
        })
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },

    hideModal() {
      this.$bvModal.hide('modal-bar-tab-open')
    },

    resetModal() {
      this.form = getInitialForm()
    },

    async onConfirm() {
      if (this.$refs.formBarTabOpen.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      this.saving = true
      const { store } = this.currentPayboxConfiguration
      const { number, customerId, consumerName, consumerPhone, observation } = this.form

      try {
        await this.$http.post(
          `/api/bar-tab/consumption/store/${store?.id}/tab-number/${number}/open`,
          {
            customerId,
            consumerName,
            consumerPhone,
            observation,
          }
        )

        this.$emit('after-open')

        this.showSuccess({ message: `Comanda ${number} aberta.` })
        this.hideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    async onSelectCustomer(customerId) {
      if (!customerId) return

      try {
        this.fetchingCustomer = true
        const { data } = await this.$http.get(`/api/customers/${customerId}`)
        this.form.consumerName = data?.name || ''

        if (Array.isArray(data?.telephones) && data?.telephones?.length > 0) {
          this.form.consumerPhone =
            data.telephones.find(t => t.type === this.telephoneTypeEnum.MOBILE)?.number || ''
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetchingCustomer = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-bar-tab-close",
            title: _vm.$t("Fechar comanda"),
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            "hide-footer": "",
            centered: "",
            size: "md",
          },
          on: { hidden: _vm.resetModal },
        },
        [
          _c(
            "FormulateForm",
            {
              ref: "formBarTabClose",
              attrs: { name: "formBarTabClose" },
              on: { submit: _vm.onConfirm },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "5" } },
                        [
                          _c("e-bar-tab-number-input", {
                            attrs: {
                              id: "modal_bar_tab_close-number",
                              name: "number",
                              label: _vm.$t("Número da comanda"),
                              required: "",
                            },
                            model: {
                              value: _vm.form.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "number", $$v)
                              },
                              expression: "form.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c("e-button", {
                            staticClass: "mr-1",
                            attrs: {
                              id: "modal_bar_tab_close-btn_cancel",
                              variant: "danger",
                              text: _vm.$t("Cancelar"),
                              "text-shortcuts": ["ESC"],
                            },
                            on: { click: _vm.hideModal },
                          }),
                          _c("e-button", {
                            attrs: {
                              id: "modal_bar_tab_close-btn_confirm",
                              variant: "primary",
                              type: "submit",
                              text: _vm.$t("Confirmar"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("bar-tab-consumption-info-sidebar", {
        ref: "barTabConsumptionInfoSidebarInModal",
        on: {
          "reload-data": function ($event) {
            return _vm.$emit("after-finish")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }